import React from 'react';
import {
   IAffiliateInvite,
   ICustomerInvite,
   IInvite,
   IUserInvite,
} from '../../lib/types';
import ClearButton from '../clear-button/clear-button';
import ModalTextbox from '../modal-textbox/modal-textbox';
import PrimaryButton from '../primary-button/primary-button';
import ToastMessage from '../toast-message/toast-message';
import './invite-modal.scss';

export interface IInviteModalProps {
   inviteTarget: string;
   headerText: string;
   bodyText: string;
   confirmText: string;
   successTextPre: string;
   successTextPost: string;
   modalClass: string;
   setModalClass: React.Dispatch<React.SetStateAction<string>>;
   submitFunction: (
      invite: IInvite
   ) => Promise<IAffiliateInvite | null> | Promise<ICustomerInvite | null> | Promise<IUserInvite | null>;
}

export default function InviteModal(props: IInviteModalProps): JSX.Element {
   const successCheckSvg = new URL('/public/images/success-check.svg', import.meta.url);
   const [inviteFirstName, setInviteFirstName] = React.useState('');
   const [inviteLastName, setInviteLastName] = React.useState('');
   const [inviteEmail, setInviteEmail] = React.useState('');
   const [inviteContact, setInviteContact] = React.useState('');
   const [userRole, setUserRole] = React.useState(''); // State for user role
   const [submitted, setSubmitted] = React.useState(false);
   const [success, setSuccess] = React.useState(false); // New state for success

   const [firstNameError, setFirstNameError] = React.useState('');
   const [lastNameError, setLastNameError] = React.useState('');
   const [emailError, setEmailError] = React.useState('');
   const [contactError, setContactError] = React.useState('');
   const [roleError, setRoleError] = React.useState('');
   const [toastClass, setToastClass] = React.useState('toast-hidden');
   const [toastbodyText, setToastBodyText] = React.useState('example');

   const submit = async (): Promise<void> => {
      let error = false;

      // Validation logic (unchanged)
      if (inviteFirstName.trim() === '') {
         setFirstNameError('First name is required.');
         error = true;
      } else if (!/^[a-zA-Z]{2,50}$/.test(inviteFirstName.trim())) {
         setFirstNameError(
            'Please enter a valid first name (2–50 characters, letters only).'
         );
         error = true;
      } else {
         setFirstNameError('');
      }

      if (inviteLastName.trim() === '') {
         setLastNameError('Last name is required.');
         error = true;
      } else if (!/^[a-zA-Z]{2,50}$/.test(inviteLastName.trim())) {
         setLastNameError(
            'Please enter a valid last name (2–50 characters, letters only).'
         );
         error = true;
      } else {
         setLastNameError('');
      }

      if (inviteEmail.trim() === '') {
         setEmailError('Email is required.');
         error = true;
      } else if (
         !inviteEmail
            .toLowerCase()
            .match(
               /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
      ) {
         setEmailError('Invalid email address.');
         error = true;
      } else {
         setEmailError('');
      }

      if (props.inviteTarget === 'Employee') {
         let normalizedContact = inviteContact.trim().replace(/[\s()-]/g, '');

         if (normalizedContact === '+1') {
            // If the input is only '+1', reset the error
            setContactError('');
         } else if (normalizedContact === '') {
            // If the input is completely empty, reset the error
            setContactError('');
         } else if (!/^(\+1)?\d{10}$/.test(normalizedContact)) {
            // If the field is not empty but invalid
            setContactError('Please enter a valid phone number with 10 digits.');
            error = true;
         } else {
            // If the field is valid
            setContactError('');
         }
      }

      if (props.inviteTarget === 'Employee' && userRole.trim() === '') {
         setRoleError('Please select an employee role.');
         error = true;
      } else {
         setRoleError('');
      }

      if (!error) {
         setSubmitted(true);
         const invite: IInvite = {
            firstName: inviteFirstName,
            lastName: inviteLastName,
            email: inviteEmail,
            ...(props.inviteTarget === 'Employee' && { contact: inviteContact }),
            ...(props.inviteTarget === 'Employee' && { role: userRole }),
         };

         try {
            const result = await props.submitFunction(invite);

            if (result) {
               // Success logic
               setSuccess(true); // Set success state to true
               setSubmitted(true);
               setToastBodyText('Invite sent successfully!');
               setToastClass('toast-visible');
               setTimeout(() => setToastClass('toast-hidden'), 3000);
            } else {
               // Failure logic
               throw new Error('API returned null or undefined');
            }
         } catch (error) {
            // Error handling logic
            setSuccess(false); // Ensure success modal doesn't render
            setSubmitted(false);
            setToastBodyText('Failed to send the invite. Please try again.');
            setToastClass('toast-visible');
            setTimeout(() => setToastClass('toast-hidden'), 3000);
         }
      }
   };

   const hideModal = (): void => {
      setSubmitted(false);
      setSuccess(false); // Reset success state
      setInviteFirstName('');
      setInviteLastName('');
      setInviteEmail('');
      setInviteContact('');
      setUserRole(''); // Reset user role
      setEmailError('');
      setFirstNameError('');
      setLastNameError('');
      setContactError('');
      setRoleError('');
      props.setModalClass('invite-modal-hidden');
      setToastClass('toast-hidden');
   };

   return (
      <div className={`invite-modal ${props.modalClass}`}>
         {!submitted || !success ? (
            <div className="invite-modal-wrapper">
               <div className="invite-modal-nav-wrapper">
                  {/* <div
                     className="invite-modal-back body-standard-semibold"
                     onClick={() => {
                        props.setModalClass('modal-hidden');
                        hideModal();
                     }}
                  >
                     <div className="back-arrow"></div>
                     Back
                  </div> */}
                  <ClearButton action={hideModal} />
               </div>
               <div className="invite-modal-header h1-headline-regular">
                  {props.headerText}
               </div>
               <div className="invite-modal-body body-standard-regular">
                  {props.bodyText}
               </div>
               <ModalTextbox
                  labelText={props.inviteTarget + ' first name'}
                  textState={inviteFirstName}
                  maxLength={50}
                  setTextState={setInviteFirstName}
               />
               {firstNameError && <span className="error-message">{firstNameError}</span>}
               <ModalTextbox
                  labelText={props.inviteTarget + ' last name'}
                  textState={inviteLastName}
                  maxLength={50}
                  setTextState={setInviteLastName}
               />
               {lastNameError && <span className="error-message">{lastNameError}</span>}
               <ModalTextbox
                  labelText={props.inviteTarget + ' email'}
                  textState={inviteEmail}
                  maxLength={150}
                  setTextState={setInviteEmail}
               />
               {emailError && <span className="error-message">{emailError}</span>}
               {props.inviteTarget === 'Employee' && (
                  <>
                     <ModalTextbox
                        labelText="Employee Role"
                        textState={userRole}
                        setTextState={setUserRole}
                        isSelect={true}
                        options={[
                           { value: 'Attorney', label: 'Attorney' },
                           { value: 'Paralegal', label: 'Paralegal' },
                           { value: 'Office Admin', label: 'Office Admin' },
                        ]}
                     />
                     {roleError && <span className="error-message">{roleError}</span>}
                     <ModalTextbox
                        labelText={props.inviteTarget + ' Contact'}
                        textState={inviteContact}
                        maxLength={15}
                        setTextState={setInviteContact}
                     />
                     {contactError && <span className="error-message">{contactError}</span>}
                  </>
               )}

               <PrimaryButton action={submit} text={props.confirmText} />
               <ToastMessage
                  type="error"
                  headerText="Error"
                  bodyText={toastbodyText}
                  toastClass={toastClass}
                  setToastClass={setToastClass}
               />
            </div>
         ) : (
            <div className="invite-modal-wrapper invite-modal-success">
               <div className="invite-modal-nav-wrapper">
                  <div className="invite-modal-back body-standard-semibold"></div>
                  <ClearButton action={hideModal} />
               </div>
               <div className="invite-modal-success-header h1-headline-regular">
                  <img src={successCheckSvg.toString()} />
                  Success!
               </div>
               <div className="invite-modal-success-text body-standard-regular">
                  {props.successTextPre}
                  <span className="invite-success-email body-standard-semibold">
                     {inviteFirstName}&apos;s
                  </span>
                  {props.successTextPost}
               </div>
            </div>
         )}
      </div>
   );
}
