import { atom, selector } from 'recoil';
import { IEmployee, Sort, SortDirection } from '../lib/types';
import { employeeInfoSelector } from './employee-info';

// Currently applied sort for the employee list
export const employeeSortAtom = atom<Sort>({
   key: 'EmployeeListSort',
   default: Sort.default, // Default sort can be by lastName or any other field
});

// Sort direction for the employee list
export const employeeSortDirectionAtom = atom<SortDirection>({
   key: 'EmployeeListSortDirection',
   default: SortDirection.ascending, // Default sorting direction is ascending
});

// Selector to sort the employee list based on selected criteria
export const filteredEmployeeSelector = selector({
   key: 'FilteredEmployeeList',
   get: ({ get }): IEmployee[] => {
      const employeeList = get(employeeInfoSelector) || [];
      const sort = get(employeeSortAtom);
      const direction = get(employeeSortDirectionAtom);

      // Make a copy of employeeList to avoid mutating the original array
      let sortedEmployees = [...employeeList];

      // Apply sorting by lastName
      if (sort === Sort.lastName) {
         sortedEmployees.sort((a, b) => {
            const normalizedA = a.lastName.toLowerCase();
            const normalizedB = b.lastName.toLowerCase();

            const comparison = normalizedA.localeCompare(normalizedB);

            // Adjust the comparison result based on the direction
            if (direction === SortDirection.descending) {
               return comparison * -1; // Reverse the comparison for descending order
            }

            return comparison; // Default to ascending order
         });
      }

      // Apply sorting by firstName
      if (sort === Sort.firstName) {
         sortedEmployees.sort((a, b) => {
            const normalizedA = a.firstName.toLowerCase();
            const normalizedB = b.firstName.toLowerCase();

            const comparison = normalizedA.localeCompare(normalizedB);

            // Adjust the comparison result based on the direction
            if (direction === SortDirection.descending) {
               return comparison * -1; // Reverse the comparison for descending order
            }

            return comparison; // Default to ascending order
         });
      }

      return sortedEmployees;
   },
});
