import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ICustomer, ICustomerDocument } from '../../lib/types';
import { customerDocumentsAtomFamily } from '../../recoil/customer-documents';
import CardBadge from '../card-badge/card-badge';
import DocumentCard from '../document-card/document-card';
import './card-customer.scss';

export interface ICardCustomerProps {
   customer: ICustomer;
}

export default function CardCustomer(props: ICardCustomerProps): JSX.Element {
   const idSeparatorSvg = new URL(
      '/public/images/id-separator.svg',
      import.meta.url
   );
   const navigate = useNavigate();

   const customerDocuments: ICustomerDocument[] = useRecoilValue(
      customerDocumentsAtomFamily(props.customer.id)
   );

   function getCustomerDetails(): void {
      navigate(`/client-details/${props.customer.id}`);
   }

   return (
      <div
         className={`${assignHoverClass(props.customer)} card-customer `}
         onClick={() => getCustomerDetails()}
      >
         <div className="card-customer-text">
            {assignBadges(props.customer)}
            <div className="customer-name h1-headline-regular">
               {props.customer.firstName} {props.customer.lastName}
            </div>
            <div className="customer-id">
               <span className="id-label body-standard-regular">ID</span>
               <img src={idSeparatorSvg.toString()} />
               <span className="id-number label-mono">
                  #{props.customer.id}
               </span>
            </div>
         </div>
         {customerDocuments.length > 0 && (
            <DocumentCard customerDocument={customerDocuments[0]} showBadge={false} />
         )}
      </div>
   );
}

export function assignBadges(customer: ICustomer): JSX.Element {
   const badges: JSX.Element[] = [];
   let key = 0;

   if (customer.recentViolation) {
      badges.push(<CardBadge key={key} type="recent-violation" />);
      key += 1;
   }
   if (customer.newCustomer) {
      badges.push(<CardBadge key={key} type="new-client" />);
      key += 1;
   }
   if (customer.alert) {
      badges.push(<CardBadge key={key} type="alert-device" />);
      key += 1;
   }
   if (customer.calibrationOverdue) {
      badges.push(<CardBadge key={key} type="calibration-overdue" />);
      key += 1;
   }
   if (customer.archived) {
      badges.push(<CardBadge key={key} type="archive" />);
      key += 1;
   }
   return <div className="customer-badges">{badges}</div>;
}

function assignHoverClass(customer: ICustomer): string {
   if (customer.recentViolation) return 'recent-violation-card';
   else if (customer.newCustomer) return 'new-client-card';
   else if (customer.alert) return 'alert-device-card';
   else if (customer.calibrationOverdue) return 'calibration-overdue-card';
   else if (customer.archived) return 'archive-card';
   else return 'no-badge-card';
}
