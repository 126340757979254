import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import api from '../../lib/api';
import {
   DocumentTypes,
   IAffiliate,
   ICustomer,
   ICustomerArchived,
   ICustomerDocument,
} from '../../lib/types';
import { affiliateAtom } from '../../recoil/affiliate';
import { customerDocumentsAtomFamily } from '../../recoil/customer-documents';
import { customersAtom } from '../../recoil/customers';
import DateAndDocument from '../date-and-document/date-and-document';
import DownloadButton from '../download-button/download-button';
import './dates-and-documents.scss';

export interface IDatesAndDocumentsProps {
   customer: ICustomer;
}

export default function DatesAndDocuments(
   props: IDatesAndDocumentsProps
): JSX.Element {
   const documents: ICustomerDocument[] = useRecoilValue(
      customerDocumentsAtomFamily(props.customer.id)
   );
   const [moveClass, setMoveClass] = React.useState('');
   const affiliate: IAffiliate | null = useRecoilValue(affiliateAtom);
   if (affiliate == null) {
      return <div></div>;
   }
   const [customerList, setCustomerList] = useRecoilState(customersAtom);

   function findDocument(documentType: DocumentTypes): ICustomerDocument {
      return documents.filter((document) => document.type == documentType)[0];
   }
   function findDocuments(documentType: DocumentTypes): ICustomerDocument[] {
      return documents.filter((document) => document.type == documentType);
   }

   const date: Date = new Date(props.customer.contactCreateUtc);
   const month: number = date.getUTCMonth() + 1;
   const day: number = date.getUTCDate();
   const year: number = date.getUTCFullYear() % 100;

   function getWindowDimensions(): {
      width: number;
      height: number;
   } {
      const { innerWidth: width, innerHeight: height } = window;
      return {
         width,
         height,
      };
   }

   const archiveCustomer = (
      customerArchived: ICustomerArchived,
      customer: ICustomer
   ): void => {
      api.putArchiveCustomer(customerArchived);

      const newCustomerList: ICustomer[] = [...customerList].map((c) => {
         if (c.id === customer.id)
            return { ...c, archived: customerArchived.archived };
         else return c;
      });
      setCustomerList(newCustomerList);
   };

   useEffect(() => {
      function handleResize(): void {
         if (getWindowDimensions().width > 744) setMoveClass('');
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
   }, []);

   const customerArchivedTrue: ICustomerArchived = {
      customerId: props.customer.id,
      affiliateId: affiliate.id,
      archived: true,
   };

   const customerArchivedFalse: ICustomerArchived = {
      customerId: props.customer.id,
      affiliateId: affiliate.id,
      archived: false,
   };

   const leaseStartDocs = findDocuments(DocumentTypes.leaseStart);
   const installCertDocs = findDocuments(DocumentTypes.installationCertificate);
   const nextCalibrationDocs = findDocuments(DocumentTypes.nextCalibration);
   const removalDocs = findDocuments(DocumentTypes.removal);
   const deInstallDocs = findDocuments(DocumentTypes.deinstallationCertificate);
   const summaryDocs = findDocuments(DocumentTypes.summary);

   return (
      <div className="dates-and-documents-wrapper">
         <div className={`dates-and-documents ${moveClass}`}>
            <div className="header-wrapper">
               <div className="header-text-wrapper">
                  <div className="body-small-semibold secondary-blue">IMPORTANT</div>
                  <div className="h1-headline-light">Dates & Documents</div>
               </div>
               <div
                  className="toggle-arrow"
                  onClick={() =>
                     moveClass == 'move-down' || moveClass == ''
                        ? setMoveClass('move-up')
                        : setMoveClass('move-down')
                  }
               ></div>
            </div>
            <div className="dates-and-documents-list">
               {leaseStartDocs.map((doc, index) => (
                  <DateAndDocument
                     customerDocument={doc}
                     text="Lease"
                     key={index}
                  />
               ))}
               {installCertDocs.map((doc, index) => (
                  <DateAndDocument
                     customerDocument={doc}
                     text="Installation"
                     key={index}
                  />
               ))}
               {removalDocs.map((doc, index) => (
                  <DateAndDocument
                     customerDocument={doc}
                     text="Removal"
                     key={index}
                  />
               ))}
               {deInstallDocs.map((doc, index) => (
                  <DateAndDocument
                     customerDocument={doc}
                     text="DeInstall"
                     key={index}
                  />
               ))}
            </div>
            <div className="dates-and-documents-soon">
               <div className="dates-and-documents-soon-title body-large-semibold">
                  Coming soon
               </div>
               <div className="date-and-document">
                  <div className="date-and-document-body">
                     <div className="date-and-document-text">
                        <div className="body-large-semibold document-summary">
                           Insurance Forms
                        </div>
                        <div className="document-info">
                           <div className="body-small-regular document-type">
                              Breathe Easy Insurance
                           </div>
                        </div>
                     </div>
                     <DownloadButton inverted={true} disabled={true} />
                  </div>
               </div>
               {/* <div className="date-and-document">
                  <div className="date-and-document-body">
                     <div className="date-and-document-text">
                        <div className="body-large-semibold document-summary">
                           Assessments
                        </div>
                        <div className="document-info">
                           <div className="body-small-regular document-type">
                              New Directions Assessments
                           </div>
                        </div>
                     </div>
                     <DownloadButton inverted={true} disabled={true} />
                  </div>
               </div> */}
            </div>
            <div className="body-small-regular">
               Client Created {month + '/' + day + '/' + year}
            </div>
            {!props.customer.archived && (
               <div
                  className="archive-link body-standard-semibold"
                  onClick={() =>
                     archiveCustomer(customerArchivedTrue, props.customer)
                  }
               >
                  Archive Client
               </div>
            )}
            {props.customer.archived && (
               <div
                  className="archive-link body-standard-semibold"
                  onClick={() =>
                     archiveCustomer(customerArchivedFalse, props.customer)
                  }
               >
                  Unarchive Client
               </div>
            )}
         </div>
         {moveClass == 'move-up' && <div className="focus-background"></div>}
      </div>
   );
}
