import React from 'react';
import './general-search.scss';

interface GeneralSearchProps {
   value: string;
   onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
   placeholder?: string;
   placeholderSecondary?: string;
   disabled?: boolean;
   clearable?: boolean;
   onClear?: () => void;
}

export default function GeneralSearch({
   value,
   onChange,
   placeholder = '',
   placeholderSecondary = '',
   disabled = false,
   clearable = false,
   onClear,
}: GeneralSearchProps): JSX.Element {
   const [placeholderClass, setPlaceholderClass] = React.useState('');


   const onFocus = (): void => {
      setPlaceholderClass('placeholder-hidden');
   };
   return (
      <div className="general-search">
         <div className="general-search-bar">
            <div className="general-search-icon"></div>
            <input
               type="text"
               className="general-search-input h1-headline-light"
               placeholder=" "
               value={value}
               onFocus={onFocus}
               onBlur={() => setPlaceholderClass('')}
               onChange={onChange}
               disabled={disabled}
            />
            <div className={`placeholder-text ${placeholderClass}`}>
               <span className="placeholder-search h1-headline-light">
                  Search&nbsp;
               </span>
               <span className="placeholder-first h1-headline-light">
                  {placeholder}
               </span>
               <span className="placeholder-second h1-headline-light">
                  {placeholderSecondary}
               </span>
            </div>
         </div>
      </div>
   );
}
