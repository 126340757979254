import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAdmin, isAffiliate, isAttorney, isOfficeAdmin, isParalegal } from '../lib/authorization';

export default function PostLoginPage(): JSX.Element {
   if (isAdmin()) {
      return <Navigate to="/firm-info" replace />;
      // return <Navigate to="/admin" replace />;
   } else if (isAffiliate() || isAttorney() || isParalegal() || isOfficeAdmin()) {
      return <Navigate to="/affiliate" replace />;
   } else {
      return <Navigate to="/" replace />;
   }
}
