import Cookies from 'js-cookie';
import { atom, selector } from 'recoil';
import api from '../lib/api';
import { getClaimEmail } from '../lib/authorization';

export interface ILoad {
   affiliateId?: number;
   userId?: number;
}

export const loadSelector = selector({
   key: 'loadSelector',
   get: async (): Promise<ILoad | null> => {
      const cookie = Cookies.get('x-affdash-actor');
      if (cookie) {
         return { affiliateId: JSON.parse(cookie)['AffiliateId'] };
      }
      const email = await getClaimEmail();
      return api.getLoad(email);
   },
});

export const loadAtom = atom<ILoad | null>({
   key: 'Load',
   default: loadSelector,
});
