import React, { useEffect, useState } from 'react';
import ToastMessage from "../toast-message/toast-message";

export const LogoutHandler: React.FC = () => {
  const [toastClass, setToastClass] = useState('toast-visible');

  useEffect(() => {
    const timer = setTimeout(() => {
      setToastClass('toast-hidden');
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <ToastMessage
      type="success"
      headerText="Logout Successful"
      bodyText="Please login again!"
      toastClass={toastClass}
      setToastClass={setToastClass}
    />
  );
};
