import { useMsal } from '@azure/msal-react';
import React from 'react';
import appConfig from '../lib/app-config';

export type LoginInButtonProps = {
   loginType: 'popup' | 'redirect';
};

// This is very rudimentary for now.
export function LoginButton({ loginType }: LoginInButtonProps) {
   const { instance: adInstance } = useMsal();
   const loginRequest = {
      scopes: [appConfig.auth.scopes.user],
      extraQueryParameters: {
         prompt: "login",
      },
   };
   
   const onLogin = async () => {
      try {
         if (loginType === "popup") {
            const response = await adInstance.loginPopup(loginRequest);
            adInstance.setActiveAccount(response.account);
            console.log("Login successful:", response);
            // Hard reload the page after successful popup login
            window.location.reload();
         } else if (loginType === "redirect") {
            await adInstance.loginRedirect(loginRequest);
         }
      } catch (error) {
         console.error("Login error:", error);
      }
   };

   // TODO: make me look nicer
   return <input type="button" onClick={onLogin} value="Sign In" />;
}
