import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Sort, SortDirection } from '../../lib/types';
import SortButton, { ISortButtonProps } from '../sort-button/sort-button';
import { employeeSortAtom, employeeSortDirectionAtom } from '../../recoil/employee-sort-filter';
import { firmSortAtom, firmSortDirectionAtom } from '../../recoil/firm-sort-filter';
import { firmInfoFiltersAtom } from '../../recoil/firm-search';
import './employee-sort-filter.scss';

export default function EmployeeSort(): JSX.Element {
   const [sort, setSort] = useRecoilState(employeeSortAtom);
   const [sortDirection, setSortDirection] = useRecoilState(employeeSortDirectionAtom);

   const handleSortChange = (newSort: Sort) => {
      // Toggle direction when the same sort is clicked
      if (sort === newSort) {
         setSortDirection((prevDirection) => 
            prevDirection === SortDirection.ascending 
               ? SortDirection.descending 
               : SortDirection.ascending
         );
      } else {
         // Change sort and default to ascending
         setSort(newSort);
         setSortDirection(SortDirection.ascending);
      }
   };

   const sortButtonProps: ISortButtonProps = {
      disabled: false,
      sortAtom: employeeSortAtom,
      sorts: [
         {
            sort: Sort.firstName, // Example sort by first name
            ascendingDescending: true,
         },
         { 
            sort: Sort.lastName, // Example sort by last name
            ascendingDescending: true,
         },
         
      ],
      onSortChange: handleSortChange,
   };

   return (
      <div className="employee-sort-filter">
         <div className="employee-filters-sort">
            <SortButton
               disabled={sortButtonProps.disabled}
               sortAtom={sortButtonProps.sortAtom}
               sorts={sortButtonProps.sorts}
               onSortChange={handleSortChange}
            />
         </div>
      </div>
   );
}
