import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Sort, SortDirection } from '../../lib/types';
import FilterPill from '../filter-pill/filter-pill';
import SortButton, { ISortButtonProps } from '../sort-button/sort-button';
import './firm-sort-filter.scss';
import { firmSortAtom, firmSortDirectionAtom } from '../../recoil/firm-sort-filter';
import { firmInfoFiltersAtom } from '../../recoil/firm-search';

export default function FirmSortFilter(): JSX.Element {
   const [sort, setSort] = useRecoilState(firmSortAtom);
   const [sortDirection, setSortDirection] = useRecoilState(firmSortDirectionAtom);
   const firmFilters: string[] = useRecoilValue(firmInfoFiltersAtom);

   const handleSortChange = (newSort: Sort) => {
      // Toggle direction when the same sort is clicked
      if (sort === newSort) {
         setSortDirection((prevDirection) => 
            prevDirection === SortDirection.ascending 
               ? SortDirection.descending 
               : SortDirection.ascending
         );
      } else {
         // Change sort and default to ascending
         setSort(newSort);
         setSortDirection(SortDirection.ascending);
      }
   };

   const sortButtonProps: ISortButtonProps = {
      disabled: false,
      sortAtom: firmSortAtom,
      sorts: [
         { 
            sort: Sort.organizationName, 
            ascendingDescending: true 
         },
      ],
      onSortChange: handleSortChange,
   };

   return (
      <div className="firm-sort-filter">
         <div className="firm-filters-sort">
            <SortButton
               disabled={sortButtonProps.disabled}
               sortAtom={sortButtonProps.sortAtom}
               sorts={sortButtonProps.sorts}
               onSortChange={handleSortChange}
            />
         </div>
      </div>
   );
}
