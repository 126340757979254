export type AppInfo = {
   environmentName: string;
   build: {
      versionNumber: string;
      gitSha: string;
      buildId: string;
   };
   auth: {
      instance: string;
      domain: string;
      clientId: string;
      audience: string;
      signUpSignInPolicyId: string;
   };
};

export interface IAffiliate {
   id: number;
   firstName: string;
   lastName: string;
   name: string;
   email: string;
   address: string;
   address2: string;
   city: string;
   state: string;
   zip: string;
   phone: string;
   organizationName: string;
   organizationCity: string;
   organizationState: string;
   title: string;
   vetted: string;
   deactivated: boolean;
}

export interface IAffiliateShort {
   id: number;
   firstName: string;
   lastName: string;
   organizationName: string;
   organizationCity: string;
   organizationState: string;
   title: string;
   vetted: string;
   deactivated: boolean;
   name?: string;
}

export interface IFirmInfo {
   organizationName: string;
   affiliateCount: number;
   customerCount: number;
}

export interface IAffiliateDeactivated {
   affiliateId: number;
   deactivated: boolean;
}

export interface IAffiliateInvite {
   affiliateFirstName: string;
   affiliateLastName: string;
   affiliateEmail: string;
}

export interface IAffiliateRecent {
   affiliateId: number;
   adminEmail: string;
   accessTime?: Date;
}

export interface IImpersonationRequest {
   affiliateId: number;
}

export interface IAffiliateImpersonation {
   affiliateId: number;
   firstName: string;
   lastName: string;
   email: string;
}

export interface ICustomer {
   id: number;
   firstName: string;
   lastName: string;
   name: string;
   address: string;
   address2: string;
   city: string;
   state: string;
   zip: string;
   email: string;
   phone: string;
   numberOfViolations: string;
   accountStatus: string;
   deviceStatus: string;
   complianceStatus: string;
   contactCreateUtc: string;
   deviceInstallUtc: string;
   leaseSignedUtc: string;
   leaseExpirationUtc: string;
   mostRecentViolationUtc: string;
   nextCalibrationDateUtc: string;
   removalDateUtc: string;
   createdUtc: string;
   updatedUtc: string;
   newCustomer: boolean;
   archived: boolean;
   recentViolation: boolean;
   alert: boolean;
   calibrationOverdue: boolean;
}

export interface ICustomerActivity {
   id: number;
   customerId: number;
   createdUtc: Date;
   updatedUtc: Date;
   eventType: string;
   message: string;
}

export interface ICustomerReportingState {
   id: number;
   customerId: number;
   stateCode: string;
   stateName: string;
   effectiveStartDate: Date;
   effectiveEndDate: Date;
   IsPrimary: boolean;
}

export interface ICustomerAlertStatus {
   id: number;
   customerId: number;
   type: string;
   message: string;
   createdUtc: Date;
   updatedUtc: Date;
}

export interface ICustomerContact {
   id: number;
   Type: string;
   value: string;
}

export interface ICustomerDocument {
   id: number;
   customerId: number;
   type: string;
   params: string;
   title: string;
   mimeType: string;
   size: string;
   createdUtc: Date;
   updatedUtc: Date;
   customerDocumentMetadata: ICustomerDocumentMetadata[];
   recentViolation: boolean;
   calibrationStatus: boolean;
   deviceAlert: boolean;
   mostRecentViolationDate: string;
}

export interface ICustomerDocumentMetadata {
   id: number;
   customerDocumentId: number;
   type: string;
   value: string;
}

export interface ICustomerArchived {
   customerId: number;
   affiliateId: number;
   archived: boolean;
}

export interface ICustomerInvite {
   customerFirstName: string;
   customerLastName: string;
   customerEmail: string;
}

export interface ICustomerRecent {
   customerId: number;
   affiliateId: number;
   accessTime?: Date;
}

export interface IEmployee {
   id: number;
   firstName: string;
   email: string;
   lastName: string;
   contactNo: string;
   roleName: string;
   vetted: boolean;
}

export interface IUserInvite {
   userFirstName: string;
   userLastName: string;
   userEmail: string;
   contactNo?: string;
   role: string;
}

export interface IInvite {
   firstName: string;
   lastName: string;
   email: string;
   contact?: string;
   role?: string;
}

export interface IHelp {
   firstName: string;
   lastName: string;
   email: string;
   firm: string;
   message: string;
}

export interface IFeatures {
   feature1: boolean;
   feature2: boolean;
}

export interface IPreferences {
   preference1: number;
   preference2: number;
}

export interface ISearchResult {
   entity;
   key?: string;
   indices;
}

export enum Filters {
   all = 'All',
   recentViolations = 'Recent Violations',
   newClients = 'New Clients',
   alerts = 'Alerts',
   overdueCalibrations = 'Overdue Calibrations',
}

export enum Sort {
   default = 'Default',
   firstName = 'First Name',
   lastName = 'Last Name',
   email = 'Email',
   mostRecentViolation = 'Most recent violation',
   numberOfViolations = 'Number of violations',
   nextCalibrationDate = 'Next calibration date',
   leaseDate = 'Lease date',
   removalDate = 'Removal Date',
   organizationName = 'Organization Name',
}

export enum SortDirection {
   ascending = 'ascending',
   descending = 'descending',
}

export interface ISortType {
   sort: Sort;
   ascendingDescending: boolean;
}

export enum DocumentTypes {
   roi = 'ROI',
   installationCertificate = 'Cert of Install',
   leaseStart = 'Lease',
   nextCalibration = 'Next Calibration',
   removal = 'Removal Auth',
   summary = 'Summary Report',
   deinstallationCertificate = 'Cert of De-install',
}
