import React from 'react';
import { IEmployee } from '../../lib/types';
import './card-employee.scss';

export interface ICardEmployeeProps {
   employee: IEmployee | null;
}

export default function CardEmployee(props: ICardEmployeeProps): JSX.Element {
   const idSeparatorSvg = new URL(
      '/public/images/id-separator.svg',
      import.meta.url
   );

   return (
      <>
         {props.employee ? (
            <div className="card-employee">
               <div className="card-employee-text">
                  <div className="employee-name body-large-semibold">
                     Role - {props.employee.roleName}
                  </div>
                  <div className="employee-name h1-headline-regular">
                     {props.employee.firstName} {props.employee.lastName}
                  </div>
                  <div className="employee-id">
                     <span className="id-label body-standard-regular">ID</span>
                     <img src={idSeparatorSvg.toString()} />
                     <span className="id-number label-mono">
                        #{props.employee.id}
                     </span>
                  </div>
               </div>
            </div>
            ):(
            <div className="card-employee cursor-none">
               <div className="card-employee-text">
                  <div className="no-record-found h1-headline-regular">
                     No record found
                  </div>
               </div>
            </div>
         )}
      </>
   );
}
