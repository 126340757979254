import React from 'react';
import { ICustomerDocument } from '../../lib/types';
import DownloadButton from '../download-button/download-button';
import './document-card.scss';
import CardBadge from '../card-badge/card-badge';

export interface IDocumentCardProps {
   customerDocument: ICustomerDocument;
   showBadge: boolean;
}

export default function DocumentCard(props: IDocumentCardProps): JSX.Element {
   const { customerDocument, showBadge } = props;
   const date: Date = new Date(customerDocument.createdUtc);
   const month: number = date.getUTCMonth() + 1;
   const day: number = date.getUTCDate();
   const year: number = date.getUTCFullYear() % 100;

   return (
      <div className="document-card">
         <div className="document-text">
            {showBadge && assignBadges(customerDocument)}
            <div className="body-standard-semibold document-summary">
               {customerDocument.title}
            </div>
            <div className="body-standard-regular document-date">
               Uploaded {month + '/' + day + '/' + year}
            </div>
            <div className="body-standard-regular document-type">
               {customerDocument.type}
            </div>
         </div>
         <DownloadButton doc={customerDocument} inverted={false} />
      </div>
   );
}

export function assignBadges(document: ICustomerDocument): JSX.Element {
   const badges: JSX.Element[] = [];
   let key = 0;

   if (document.recentViolation) {
      badges.push(<CardBadge key={key} type="recent-violation" />);
      key += 1;
   }
   if (document.deviceAlert) {
      badges.push(<CardBadge key={key} type="alert-device" />);
      key += 1;
   }
   if (document.calibrationStatus) {
      badges.push(<CardBadge key={key} type="calibration-overdue" />);
      key += 1;
   }
   return <div className="customer-badges">{badges}</div>;
}
