import { ILoad } from '../recoil/load';
import {
   AuthScope,
   deleteJson,
   getBytes,
   getJson,
   postJson,
   putJson,
} from './http-client';
import {
   IAffiliate,
   IAffiliateDeactivated,
   IAffiliateInvite,
   IAffiliateRecent,
   IAffiliateShort,
   ICustomer,
   ICustomerActivity,
   ICustomerAlertStatus,
   ICustomerArchived,
   ICustomerContact,
   ICustomerDocument,
   ICustomerInvite,
   ICustomerRecent,
   ICustomerReportingState,
   IEmployee,
   IFeatures,
   IFirmInfo,
   IHelp,
   IImpersonationRequest,
   IInvite,
   IPreferences,
   IUserInvite,
} from './types';

const BASE_URL = '/api';

class Api {
   // async getAdminAffiliates(): Promise<IAffiliateShort[] | null> {
   //    return await getJson(`${BASE_URL}/admin/affiliates`, AuthScope.admin);
   // }

   async getAdminAffiliates(organizationName: string): Promise<IAffiliateShort[] | null> {
      const encodedOrganizationName = encodeURIComponent(organizationName);
      return await getJson(`${BASE_URL}/admin/affiliateDetails?organizationName=${encodedOrganizationName}`, AuthScope.admin);
   }

   async getFirmInfo(): Promise<IFirmInfo[] | null> {
      return await getJson(`${BASE_URL}/admin/affiliatesCount`, AuthScope.admin);
   }

   async getAdminAffiliate(affiliateId: number): Promise<IAffiliate | null> {
      return await getJson(
         `${BASE_URL}/admin/affiliates/${affiliateId}`,
         AuthScope.admin
      );
   }

   async getAdminAffiliateCustomers(
      affiliateId: number
   ): Promise<ICustomer[] | null> {
      return await getJson(
         `${BASE_URL}/admin/affiliates/${affiliateId}/customers`,
         AuthScope.admin
      );
   }

   async getAdminAffiliateEmployees(
      affiliateId: number
   ): Promise<IEmployee[] | null> {
      return await getJson(
         `${BASE_URL}/admin/affiliates/${affiliateId}/users`,
         AuthScope.admin
      );
   }

   async putDeactivateAffiliate(
      affiliateDeactivated: IAffiliateDeactivated
   ): Promise<IAffiliateDeactivated[] | null> {
      return await putJson(
         `${BASE_URL}/admin/affiliates/${affiliateDeactivated.affiliateId}/deactivate`,
         affiliateDeactivated,
         AuthScope.admin
      );
   }

   async getRecentAffiliates(
      adminEmail: string
   ): Promise<IAffiliateRecent[] | null> {
      return await getJson(
         `${BASE_URL}/admin/affiliates/${window.encodeURIComponent(
            window.btoa(adminEmail)
         )}/recent`,
         AuthScope.admin
      );
   }

   async postRecentAffiliate(
      affiliateRecent: IAffiliateRecent
   ): Promise<IAffiliateRecent | null> {
      return await postJson(
         `${BASE_URL}/admin/affiliates/recent`,
         affiliateRecent,
         AuthScope.admin
      );
   }

   async postInviteAffiliate(
      invite: IInvite
   ): Promise<IAffiliateInvite | null> {
      const affiliateInvite: IAffiliateInvite = {
         affiliateFirstName: invite.firstName,
         affiliateLastName: invite.lastName,
         affiliateEmail: invite.email,
      };
      return await postJson(
         `${BASE_URL}/admin/affiliates/invite`,
         affiliateInvite,
         AuthScope.admin
      );
   }

   async postImpersonateAffiliate(
      impersonationRequest: IImpersonationRequest
   ): Promise<IImpersonationRequest | null> {
      return await postJson(
         `${BASE_URL}/auth/impersonate`,
         impersonationRequest,
         AuthScope.admin
      );
   }

   async deleteImpersonateAffiliate(): Promise<void> {
      await deleteJson(`${BASE_URL}/auth/impersonate`, AuthScope.admin);
   }

   async getCustomers(affiliateId: number): Promise<ICustomer[] | null> {
      return await getJson(
         `${BASE_URL}/affiliates/${affiliateId}/customers`,
         AuthScope.affiliate
      );
   }

   async getEmployees(affiliateId: number): Promise<IEmployee[] | null> {
      return await getJson(
         `${BASE_URL}/affiliates/${affiliateId}/users`,
         AuthScope.affiliate
      );
   }

   async getAffiliate(affiliateId: number): Promise<IAffiliate | null> {
      return await getJson(
         `${BASE_URL}/affiliates/${affiliateId}`,
         AuthScope.affiliate
      );
   }

   async getFeatures(affiliateId: number): Promise<IFeatures | null> {
      return await getJson(
         `${BASE_URL}/load/${affiliateId}/features`,
         AuthScope.affiliate
      );
   }

   async getPreferences(affiliateId: number): Promise<IPreferences | null> {
      return await getJson(
         `${BASE_URL}/load/${affiliateId}/preferences`,
         AuthScope.affiliate
      );
   }

   async getLoad(affiliateEmail: string): Promise<ILoad | null> {
      return await getJson(
         `${BASE_URL}/load/initial/${affiliateEmail}`,
         AuthScope.affiliate
      );
   }

   async getCustomerActivities(
      affiliateId: number,
      customerId: number
   ): Promise<ICustomerActivity[] | null> {
      return await getJson(
         `${BASE_URL}/affiliates/${affiliateId}/customers/${customerId}/activities`,
         AuthScope.affiliate
      );
   }

   async getCustomerReportingStates(
      affiliateId: number,
      customerId: number
   ): Promise<ICustomerReportingState[] | null> {
      return await getJson(
         `${BASE_URL}/affiliates/${affiliateId}/customers/${customerId}/reportingstates`,
         AuthScope.affiliate
      );
   }

   async getCustomerAlertStatuses(
      affiliateId: number,
      customerId: number
   ): Promise<ICustomerAlertStatus[] | null> {
      return await getJson(
         `${BASE_URL}/affiliates/${affiliateId}/customers/${customerId}/alert-statuses`,
         AuthScope.affiliate
      );
   }

   async getCustomerContacts(
      affiliateId: number,
      customerId: number
   ): Promise<ICustomerContact[] | null> {
      return await getJson(
         `${BASE_URL}/affiliates/${affiliateId}/customers/${customerId}/contacts`,
         AuthScope.affiliate
      );
   }

   async getCustomerDocuments(
      affiliateId: number,
      customerId: number
   ): Promise<ICustomerDocument[] | null> {
      return await getJson(
         `${BASE_URL}/affiliates/${affiliateId}/customers/${customerId}/documents`,
         AuthScope.affiliate
      );
   }

   async putArchiveCustomer(
      customerArchived: ICustomerArchived
   ): Promise<ICustomerArchived[] | null> {
      return await putJson(
         `${BASE_URL}/affiliates/${customerArchived.affiliateId}/customers/${customerArchived.customerId}/archive`,
         customerArchived,
         AuthScope.affiliate
      );
   }

   async getRecentCustomers(
      affiliateId: number
   ): Promise<ICustomerRecent[] | null> {
      return await getJson(
         `${BASE_URL}/affiliates/${affiliateId}/recent`,
         AuthScope.affiliate
      );
   }

   async postRecentCustomer(
      customerRecent: ICustomerRecent
   ): Promise<ICustomerRecent | null> {
      return await postJson(
         `${BASE_URL}/affiliates/${customerRecent.affiliateId}/recent`,
         customerRecent,
         AuthScope.affiliate
      );
   }

   async postInviteCustomer(invite: IInvite): Promise<ICustomerInvite | null> {
      const customerInvite: ICustomerInvite = {
         customerFirstName: invite.firstName,
         customerLastName: invite.lastName,
         customerEmail: invite.email,
      };
      return await postJson(
         `${BASE_URL}/affiliates/invite`,
         customerInvite,
         AuthScope.affiliate
      );
   }

   async postAddEmployee(invite: IInvite, affiliateId: number,): Promise<IUserInvite | null> {
      if (!invite.role) {
         throw new Error('Role is required for adding an employee.');
      }

      const addEmployee: IUserInvite = {
         userFirstName: invite.firstName,
         userLastName: invite.lastName,
         userEmail: invite.email,
         contactNo: invite.contact,
         role: invite.role,
      };
      return await postJson(
         `${BASE_URL}/affiliates/${affiliateId}/inviteUser`,
         addEmployee,
         AuthScope.affiliate,
      );
   }

   async postHelp(help: IHelp): Promise<IHelp | null> {
      return await postJson(
         `${BASE_URL}/affiliates/help`,
         help,
         AuthScope.affiliate
      );
   }

   async downloadDocument(
      affiliateId: number,
      customerId: number,
      documentId: number
   ): Promise<Blob> {
      return await getBytes(
         `${BASE_URL}/affiliates/${affiliateId}/customers/${customerId}/documents/${documentId}`,
         AuthScope.affiliate
      );
   }
}
export default new Api();
