import { atom, selector } from 'recoil';
import { IEmployee } from '../lib/types'; // Assuming you have a type definition for EmployeeInfo
import api from '../lib/api';
import { affiliateAtom } from './affiliate';


export const employeeInfoSelector = selector<IEmployee[]>({
   key: 'EmployeeInfoSelector',
   get: async ({ get }) => {
      const affiliate = get(affiliateAtom);

      if (affiliate == null) return [];

      try {
         // Fetch employee data from an API or other source
         const response = await api.getEmployees(affiliate.id);
         return response ? response : [];
      } catch (error) {
         console.error('Failed to fetch employee information:', error);
         return [];
      }
   },
});

export const employeeInfoAtom = atom({
   key: 'employeeInfo', // Unique ID (with respect to other atoms/selectors)
   default: employeeInfoSelector, // Default value (initial value) set to employeeInfoSelector
});
