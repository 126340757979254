import Fuse from 'fuse.js';
import { atom, selector } from 'recoil';
import { ISearchResult } from '../lib/types';
import { filteredEmployeeSelector } from './employee-sort-filter';

// currently applied search for employees
export const employeesSearchQueryAtom = atom<string>({
   key: 'EmployeeListSearchQuery',
   default: '',
});

// searches employees list based on employeesSearchQueryAtom
export const searchedEmployeesSelector = selector({
   key: 'SearchedEmployeeList',
   get: ({ get }): ISearchResult[] => {
      const query = get(employeesSearchQueryAtom);
      const employees = get(filteredEmployeeSelector) || []; // Assuming this gives you the list of employees

      const options = {
         includeScore: true,
         includeMatches: true,
         threshold: 0.5,
         keys: ['firstName', 'lastName', 'id'], // You can modify these keys based on your employee object structure
      };

      const fuse = new Fuse(employees, options);
      const results = fuse.search(query);

      return results.map((result) => ({
         entity: result.item,
         key: result.matches?.[0].key || '',
         indices: result.matches?.[0].indices || [],
      }));
   },
});
