import React, { ReactElement } from 'react';
import { useRecoilValue } from 'recoil';
import { ICustomer, ICustomerDocument } from '../../lib/types';
import {
   customerDocumentFilterAtom,
   customerDocumentFiltersSelector,
   filteredCustomerDocumentsSelector,
} from '../../recoil/customer-document-filter';
import Calendar from '../calendar/calendar';
import DocumentCard from '../document-card/document-card';
import FilterDropdown, {
   IFilterDropdownProps,
} from '../filter-dropdown/filter-dropdown';
import HelpModal from '../help-modal/help-modal';
import SecondaryButton from '../secondary-button/secondary-button';
import './customer-documents.scss';

export interface ICustomerDocumentProps {
   customer: ICustomer;
}

export function CustomerDocuments(props: ICustomerDocumentProps): ReactElement {
   const customerDocumentsFiltered: ICustomerDocument[] = useRecoilValue(
      filteredCustomerDocumentsSelector(props.customer.id)
   );
   const customerDocumentFilters: string[] = useRecoilValue(
      customerDocumentFiltersSelector(props.customer.id)
   );

   const dropdownFilterProps: IFilterDropdownProps = {
      filters: customerDocumentFilters,
      filterAtom: customerDocumentFilterAtom,
      clearHeader: false,
      defaultFilter: 'All documents',
      uniqueName: 'documents',
   };

   const [modalClass, setModalClass] = React.useState('modal-hidden');
   const showModal = (): void => {
      setModalClass('');
   };

   return (
      <div className="customer-documents">
         <div className="customer-documents-header">
            {/* <FilterDropdown
               filters={dropdownFilterProps.filters}
               filterAtom={dropdownFilterProps.filterAtom}
               clearHeader={dropdownFilterProps.clearHeader}
               defaultFilter={dropdownFilterProps.defaultFilter}
               uniqueName={dropdownFilterProps.uniqueName}
            /> */}
            <Calendar />
         </div>
         <div className="customer-documents-list">
            {customerDocumentsFiltered.map((customerDocument) => (
               <DocumentCard
                  key={customerDocument.id}
                  customerDocument={customerDocument}
                  showBadge={true}
               />
            ))}
         </div>
         {/* <div className="customer-documents-help-text body-small-regular">
            No other documents within the selected date range.
         </div> */}
         <SecondaryButton
            text="Not seeing what you’re looking for?"
            action={showModal}
         />
         <HelpModal modalClass={modalClass} setModalClass={setModalClass} />
      </div>
   );
}
